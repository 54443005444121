.cv-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--white);
  padding: 3em 5em;
  border: dashed 8px #00000010;
  border-radius: 25px;
  gap: 40px;
  cursor: pointer;
  position: relative;
  height: 400px;
  margin: 30px 0 0 0;
}

.cv-uploader h2 {
  font-size: 1.5em;
  font-weight: 600;
}

.cv-uploader ::selection {
  background-color: transparent;
}

.cv-uploader svg {
  opacity: 0.3;
}

.already-existing-advert {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .cv-uploader {
    padding: 3em 2em;
  }

  .cv-uploader h2 {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 576px) {
  .cv-uploader {
    padding: 2em 1em;
    height: 300px;
  }

  .cv-uploader h2 {
    font-size: 1em;
  }
}
