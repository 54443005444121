.profile-picture-uploader {
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  padding: 3em 4em;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 80px;
  border: dashed 8px #00000010;
  cursor: pointer;
  transition: all 100ms linear;
  position: relative;
  width: max-content;
}

.profile-picture-uploader.w-full {
  width: 100%;
  padding: 1.5em 2.5em;
  justify-content: center;
}

input[type="file"]#file {
  display: none;
}

.profile-picture-uploader:hover {
  opacity: 0.75;
}

.profile-picture-uploader img {
  border-radius: 15px 25px;
  height: 150px;
  width: 150px;
}

.profile-picture-uploader .empty-new-profile-picture {
  height: 150px;
  width: 150px;
  background-color: #00000010;
  border-radius: 15px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.profile-picture-uploader svg {
  opacity: 0.2;
}

.profile-picture-uploader ~ .actions {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 45px 0 0 0;
}

@media screen and (max-width: 790px) {
  .profile-picture-uploader {
    gap: 40px;
  }
}

@media screen and (max-width: 675px) {
  .profile-picture-uploader {
    padding: 2em;
  }
}

@media screen and (max-width: 635px) {
  .profile-picture-uploader :not(:last-child) {
    display: none;
  }

  .profile-picture-uploader :last-child {
    margin: 0 auto;
  }

  .profile-picture-uploader {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .profile-picture-uploader ~ .actions {
    flex-direction: column;
    gap: 10px;
  }

  .profile-picture-uploader ~ .actions button {
    width: 100%;
  }
}
