.tabs .options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: 15px;
  padding: 0 2em;
}

.tabs .options .option-button {
  border: none;
  font-size: 1.2em;
  padding: 0.6em 0;
  width: 50%;
  position: relative;
  overflow: hidden;
}

.tabs .options .option-button.open {
  color: var(--socraft-yellow) !important;
}

.tabs .options .option-button.open::before {
  content: "";
  position: absolute;
  left: 10%;
  bottom: 0;
  right: 10%;
  height: 5px;
  border-radius: 5px 5px 0 0;
  background-color: var(--socraft-yellow);
  transform: translateX(0);
  transition: all 200ms linear;
}

.tabs .content {
  margin: 25px 0 0 0;
}

.tabs .content .option-content:not(.open) {
  display: none;
}

@media screen and (max-width: 768px) {
  .tabs .options {
    padding: 0 1em;
  }

  .tabs .options .option-button {
    font-size: 1em;
  }
}

@media screen and (max-width: 576px) {
  .tabs .options {
    height: 3em;
    padding: 0 0.5em;
  }

  .tabs .options .option-button {
    font-size: 0em;
  }

  .tabs .options .option-button.open {
    font-size: 0em;
  }

  .tabs .options .option-button.open::before {
    height: 0px;
  }

  .tabs .option-button {
    width: 100%;
  }
}
