.crafter-card {
  padding: 20px 25px;
  border-radius: 20px;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  flex: 1 0 auto;
  position: relative;
}

.open-to-work-badge {
  position: absolute;
  top: -1em;
  right: -1em;
  background-color: var(--success);
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0.3em 1em;
  border-radius: 13px;
}

.crafter-card .header {
  display: flex;
  gap: 8px;
}

.crafter-card .header .avatar {
  border-radius: 15px 25px;
  height: 160px;
  width: 160px;
}

.crafter-card .header .infos {
  display: flex;
  flex-direction: column;
}

.crafter-card .header .infos .fullname {
  margin: 20px 0 20px 20px;
  display: flex;
  flex-wrap: wrap;
  max-width: 95%;
  gap: 7px;
}

.crafter-card .header .infos .contact {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 50px;
  gap: 15px;
}

.crafter-card .header .infos .contact .info {
  position: relative;
}

.crafter-card .header .infos .contact .info::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 5px;
  background-color: var(--socraft-yellow);
  height: 10px;
  width: 10px;
  border-radius: 2.5px;
}

.crafter-card .roles {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
}

.crafter-card .roles .role {
  font-size: 0.9rem;
  font-weight: 500;
  background-color: #28282820;
  border: solid 1px #28282850;
  padding: 4px 10px;
  border-radius: 7px;
}

.crafter-card .actions {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px 0 0 0;
}

.crafter-card .profile-id {
  font-size: 0.75em;
  color: var(--gray);
  margin: 10px 0;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  opacity: 0.65;
  padding: 0.6em;
  width: max-content;
  border-radius: 5px;
  transition:
    background-color 0.3s ease,
    opacity 0.3s ease;
}

.crafter-card .profile-id:hover {
  background-color: rgb(240, 239, 239);
  opacity: 1;
}

.crafter-card .profile-id .info {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.crafter-card .profile-id .info svg {
  margin-right: 8px;
  font-size: 1em;
  color: var(--primary);
}

.crafter-card .profile-id .tooltip {
  display: none;
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 1em;
  width: max-content;
  position: absolute;
  top: -40px; /* Adjust as needed */
  right: 0;
}

.crafter-card .profile-id .info:hover .tooltip {
  display: block;
}

.crafter-card .profile-id .icon {
  font-size: 1em;
  color: var(--primary);
  display: flex;
  align-items: center;
}
.crafter-card .actions button {
  font-size: 0.9em;
  padding: 0.5em 1.35em;
  border-radius: 15px;
  float: right;
}

@media screen and (max-width: 660px) {
  .crafter-card .header {
    flex-direction: column;
  }

  .crafter-card .header .infos .fullname {
    margin: 10px 0 20px 5px;
    font-size: clamp(1rem, 2.5vw, 2rem);
  }

  .crafter-card .header .infos .contact {
    margin: 0 0 0 30px;
  }

  .crafter-card .actions {
    margin: 30px 0 0 0;
  }
}

@media screen and (max-width: 476px) {
  .crafter-card {
    padding: 15px 15px;
    width: 19.7em;
  }
}
