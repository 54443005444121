.search-bar {
  margin: 3em 0 0 0;
}

.roles-filter {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin: 1em 0 0 0;
}

.roles-filter .role {
  font-size: 1em;
  font-weight: 500;
  padding: 6px 12px;
  border: solid 1px var(--border-color);
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
  transition: all 50ms linear;
  user-select: none;
}

.roles-filter .role.selected {
  background-color: var(--socraft-yellow);
}
