.events .card.event {
  background-color: transparent;
  box-shadow: none;
  border: solid 1px var(--border-color);
  padding: 0.2em 0;
}

.events .card.event header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3em;
  border-bottom: solid 1px var(--border-color);
}

.events .card.event header h3 {
  font-weight: 600;
  font-size: 1em;
  opacity: 0.8;
  margin-block: 10px;
}

.events .card.event header .date {
  font-weight: 700;
  opacity: 0.3;
  font-size: 0.8em;
}

.events .card.event p {
  margin: 0 3em 2em 3em;
}

.events .card.event {
  margin: 20px 0 0 0;
}
