.sidebar {
  position: fixed;
  top: 3.5em;
  left: 2em;
  bottom: 3.5em;
  padding: 3em 1em;
  width: 250px;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: 20px;
  text-align: center;
  overflow-y: auto;
  transition: all 100ms linear;
  z-index: 99;
}

.hamburger {
  cursor: pointer;
  display: none;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  width: max-content;
  padding: 3px 5px 1px 5px;
  border-radius: 15px;
  position: fixed;
  top: 1em;
  left: 1em;
  z-index: 100;
}

.hamburger input {
  display: none;
}

.hamburger svg {
  height: 3em;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: var(--socraft-dark);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  transition:
    stroke-dasharray 300ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
  transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}

@media screen and (max-width: 1350px) {
  .sidebar {
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 0;
  }
}

@media screen and (max-width: 1060px) {
  .sidebar {
    transform: translateX(-100%);
    padding: 7em 1em 3em 1em;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .hamburger {
    display: block;
  }
}

.sidebar header .logo {
  cursor: pointer;
}

.sidebar nav {
  margin: 3em 0;
}

.sidebar nav .environment-badge {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--socraft-yellow);
  padding: 0.5em 1em;
  font-size: 1em;
  font-weight: 600;
}
