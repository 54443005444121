.toggleable {
  width: 100%;

  &:not(:first-child) .label {
    margin: 1em 0 0 0;
  }

  .label {
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    padding: 0.75em 1.5em;
    font-size: 1.2em;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;

    &::selection {
      background-color: transparent;
    }

    .icon {
      transition: all 250ms ease-in-out;
      flex-shrink: 0;
    }

    .text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-grow: 1;
      margin: 0 10px;
    }
  }

  .content {
    display: none;
    transition: all 250ms ease-in-out;
    background-color: var(--white);
    width: 100%;
    padding: 1.5em 3em 2em 3em;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
    margin: 1em 0 0 0;
    position: relative;
    animation: appear 100ms linear;
  }

  &.opened {
    .content {
      display: block;
      transition: all 250ms ease-in-out;
    }

    .label .icon.rotate {
      transform: rotate(135deg);
    }
  }

  @keyframes appear {
    from {
      opacity: 0;
    }
  }

  @media screen and (max-width: 715px) {
    .content {
      padding: 1em 2em 2em 2em;
    }
  }

  @media screen and (max-width: 576px) {
    .label {
      padding: 0.75em 1em;
      font-size: 1em;
      height: 3em;

      .icon {
        font-size: 1.5em;
      }
    }

    .content {
      padding: 1em 1em 1em 1em;

      ul {
        font-size: 0.95em;
      }

      button {
        font-size: 0.9em;
      }

      .status {
        font-size: 0.9em;
      }
    }
  }
}
